import { Card, EmptyState, Layout, Page } from "@shopify/polaris";
import { APP_CONFIG } from "base/consts/app";
import type { NextPage } from "next";
import useTranslation from "next-translate/useTranslation";
import Head from "next/head";

const Error404Page: NextPage = () => {
  const { t: translate } = useTranslation("error");

  return (
    <>
      <Head>
        <title>{translate('notFoundPageTitle')} | {APP_CONFIG.APP_NAME}</title>
      </Head>
      <Page>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <EmptyState
                heading={translate('notFoundEmptyStateHeading')}
                action={{content: translate('goToHomeButton'), url: '/'}}
                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                fullWidth
              >
                <p>{translate('notFoundEmptyStateDescription')}</p>
              </EmptyState>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  )
}

export default Error404Page;
